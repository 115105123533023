<template>
  <div>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-card-text>
        <div
          class="d-flex justify-content-center mb-3"
          v-if="ProgessStart == 1"
        >
          <b-spinner
            variant="primary"
            type="grow"
            label="We are fetching details, please wait..."
          ></b-spinner>
        </div>

        <v-container>
          <br />

          <!-- <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <br />
              <v-btn color="blue" class="mr-4" @click="refreshPageData">
                Refresh
              </v-btn>
              <p></p>
            </v-col>
          </v-row> -->

          <v-container class="py-0">
            <v-form
              ref="form1"
              v-model="valid1"
              lazy-validation
              id="createForm"
              v-on:submit.prevent="searchForm"
            >
              <v-row wrap>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="MemberUsername"
                    :rules="MemberUsernameRules"
                    label="Username"
                    hint="Enter the username"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid1"
                    @click.prevent="searchForm"
                    :loading="SearchFlag"
                    color="success"
                    class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >
                    Search
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>

          <v-container class="py-0" v-if="ResultFlag">
            <v-form
              ref="form2"
              v-model="valid2"
              lazy-validation
              id="createForm"
              v-on:submit.prevent="searchForm"
            >
              <v-row wrap>
                <v-col md="4" sm="6">
                  <label> Member Name </label>
                  <h4>
                    {{ Member.FirstName }} {{ Member.MiddleName }}
                    {{ Member.LastName }}
                  </h4>
                </v-col>
                <v-col md="4" sm="6">
                  <label> Mobile No </label>
                  <h4>
                    {{ Member.MobileNoTxt }}
                  </h4>
                </v-col>
                <v-col md="4" sm="6">
                  <label> Email Id </label>
                  <h4>
                    {{ Member.EmailIdTxt }}
                  </h4>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col>
                  <label> Zone Name </label>
                  <h4>
                    {{ Member.ZoneName }}
                  </h4>
                </v-col>
                <v-col md="4" sm="6">
                  <label> LOM Name </label>
                  <h4>
                    {{ Member.LomName }}
                  </h4>
                </v-col>
                <v-col md="4" sm="6">
                  <label> Role </label>
                  <h4>
                    {{ Member.RoleName }}
                  </h4>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col md="4" sm="6">
                  <label> SMA Zone Name </label>
                  <h4>
                    {{ Member.SmaZoneName }}
                  </h4>
                </v-col>
                <v-col md="4" sm="6">
                  <label> Active Status </label>
                  <h4>
                    {{ Member.UserActiveStatusTxt }}
                  </h4>
                </v-col>
              </v-row>
              <v-row wrap v-if="AdminFlag">
                <v-col md="4" sm="6">
                  <label> Current Designation Name </label>
                  <h4>
                    {{ Member.CurrentDesignationName }}
                  </h4>
                </v-col>
                <v-col md="4" sm="6">
                  <label> Current Role Name </label>
                  <h4>
                    {{ Member.CurrentRoleName }}
                  </h4>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="3">
                  <v-img
                    :src="Member.ProfilePic"
                    :alt="Member.FirstName"
                    lazy-src="/no-image-available.png"
                    max-width="100"
                    max-height="100"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
              </v-row>
              <v-row wrap>
                <v-col align="center" cols="12" md="12">
                  <v-btn
                    :disabled="!valid2"
                    @click.prevent="confirmAlert"
                    :loading="SubmitFlag"
                    color="#ff7f50"
                    class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >
                    Reset Password
                  </v-btn>
                  <v-btn
                    v-if="PagePermissionFlag"
                    @click.prevent="editAlert"
                    color="#2fb65a"
                    class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >
                    Edit member details
                  </v-btn>
                  <v-btn
                    v-if="AdminFlag"
                    @click.prevent="updateRole"
                    :loading="UpdateRoleLoadingFlag"
                    color="#4285f4"
                    class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >
                    Update member role
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>

          <v-dialog v-model="editRecordPrompt" max-width="85%">
            <member-edit
              pageDescription="Edit Member Details"
              :editRecordPrompt="editRecordPrompt"
              @hideEditRecordPrompt="hideEditRecordPrompt"
              :recordIndex="recordToEdit"
              v-if="editRecordPrompt"
            ></member-edit>
          </v-dialog>

          <v-container class="py-0" v-if="Members.length > 0">
            <h3>Members List</h3>
            <br />
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-text-field
                  v-model="search1"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                ></v-text-field>
                <br />
                <v-data-table
                  class="elevation-1"
                  :single-select="false"
                  v-model="selected1"
                  :headers="tableColumns1"
                  :items="Members"
                  :items-per-page="50"
                  :search="search1"
                  :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50],
                  }"
                  item-key="MemberId"
                >
                  <template v-slot:item.ActiveStatusTxt="{ item }">
                    <v-chip
                      :color="getActiveStatusColor(item.ActiveStatusTxt)"
                      draggable
                      dark
                      >{{ item.ActiveStatusTxt }}</v-chip
                    >
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          icon
                          size="25px"
                          color="primary"
                          @click="previewData(item, e)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-file-document-edit
                        </v-icon>
                      </template>
                      <span> Preview </span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>

          <br />
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";
import MemberEdit from "@/view/pages/members/MemberEdit.vue";

export default {
  mixins: [common],
  components: {
    MemberEdit,
  },
  data() {
    return {
      ProgessStart: 0,
      uploadPercentage: 0,

      SuccessMessageTxtFlag: 0,
      SuccessMessageTxt: "",

      ErrorMessageTxtFlag: 0,
      ErrorMessageTxt: "",

      DownloadFlag: 0,
      InstructionFlag: 0,

      SelectFlag: true,
      DeleteFlag: false,

      alert: {},

      valid1: true,
      valid2: true,

      MemberUsername: "",
      MemberUsernameRules: [(v) => !!v || "Username is required"],

      lastDate: "15-JAN-2021 11:59 PM",

      RoleId: "",
      AdminFlag: false,

      ResultFlag: 0,
      SearchFlag: false,
      SubmitFlag: false,
      UpdateRoleLoadingFlag: false,
      BtnShowFlag: 0,

      NewMembers: [],

      bgColor: "#778899",
      position: "top-right",
      fabActions: [
        /*
        {
          name: 'deleteBtn',
          icon: 'delete',
          color: 'red',
        },
        */
        {
          name: "previewBtn",
          icon: "search",
          color: "orange",
        },
      ],

      singleSelect: true,
      selected1: [],
      selected2: [],
      search1: "",
      search2: "",
      loading: true,

      addRecordPrompt: false,
      editRecordPrompt: false,
      previewRecordPrompt: false,

      recordToEdit: "",

      PaymentPaidDate: "",
      menu2: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "", value: "actions", sortable: false, groupable: false },
        { text: "Status", value: "ActiveStatusTxt", groupable: false },
        { text: "Register No", value: "AwardsRegisterNo" },
        { text: "Zone Name", value: "ZoneName" },
        { text: "LOM", value: "LomName" },
        { text: "Award Type", value: "AwardsTypeTitle" },
        { text: "Token", value: "AwardsToken", groupable: false },
        {
          text: "President Details",
          value: "LomPresidentName",
          groupable: false,
        },
        {
          text: "100% Efficiency",
          value: "FullEfficiencyPoints",
          groupable: false,
        },
        { text: "Score", value: "TotalAssessmentScore", groupable: false },
      ],
      rows: [],
      Member: {},
      Members: [],
      TotalMembers: 0,

      tableColumns1: [],
      tableColumns2: [],
      editedIndex: -1,
      editedItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },
      defaultItem: {
        name: "",
        calories: 0,
        fat: 0,
        carbs: 0,
        protein: 0,
      },

      PageInfo: {},
      PageTitle: "",
      PageDescription: "",
      CurrentYearId: 0,
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
      PagePermissionFlag: false,
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        var PagePermissionFlag = tr.PagePermissionFlag;
        console.log({ PagePermissionFlag });
        this.PagePermissionFlag = PagePermissionFlag;
        if (!PagePermissionFlag) {
          this.$router.push("/access-denied");
        }

        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;
        this.FloatingButtonOptions = tr.FloatingButtonOptions;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        this.CurrentYearId = CurrentJciYearId;
      }
    },
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    MemberUsername: function () {
      console.log("MemberUsername called");
      this.Member = {};
      this.ResultFlag = false;
    },
  },
  created() {
    // this.initialize()
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "users",
        Action: "list",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    hideEditRecordPrompt() {
      console.log("hideEditRecordPrompt called");
      this.editRecordPrompt = false;
      this.searchForm();
    },
    editAlert() {
      console.log("editAlert called");
      var tr = this.Member;
      // var n1 = tr.length
      var n1 = Object.keys(tr).length;
      console.log("n1=" + n1 + ", tr=" + JSON.stringify(tr));
      if (n1 > 0) {
        // var CityId = tr.CityId;
        this.recordToEdit = tr.MemberId;
        this.editRecordPrompt = true;
      } else {
        var message = "Kindly select one record to edit";
        this.errorMessage(message);
      }
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "Do you want to reset the password?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Yes`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `No`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        this.toast("error", "Kindly fill the required fields", true);
      }
    },
    searchForm() {
      console.log("searchForm called");

      var validate1 = this.$refs.form1.validate();

      console.log("validate1=" + validate1);

      if (validate1) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/users/show";
        var upload = {
          UserInterface: 1,
          Username: this.MemberUsername,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.Member = {};
        this.ResultFlag = 0;
        this.SearchFlag = true;
        this.AdminFlag = false;

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        // thisIns.pageLoadingOn()
        thisIns.ProgessStart = 1;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.ProgessStart = 0;
            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              // thisIns.sweetAlert("success", output, true);
              //   thisIns.Members = records;
              thisIns.ResultFlag = 1;
              thisIns.Member = records;
              thisIns.AdminFlag = records.AdminFlag;
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.ProgessStart = 0;
            thisIns.SearchFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message, false);
      }
    },
    submitForm() {
      console.log("submitForm called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/users/reset-password";
        var upload = {
          UserInterface: 1,
          Username: this.MemberUsername,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.SubmitFlag = true;

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        // thisIns.pageLoadingOn()
        thisIns.ProgessStart = 1;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.ProgessStart = 0;
            thisIns.SubmitFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, false);
              //   thisIns.Members = records;
              thisIns.ResultFlag = 0;
              thisIns.Member = {};
              thisIns.$refs.form1.reset();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "Username should not be empty.";
        this.sweetAlert("error", message, false);
      }
    },
    updateRole() {
      console.log("updateRole called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/users/update-role";
        var upload = {
          UserInterface: 1,
          Username: this.MemberUsername,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        this.UpdateRoleLoadingFlag = true;
        this.ProgessStart = 1;

        const thisIns = this;
        var output = "";
        var records = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()
            thisIns.ProgessStart = 0;
            thisIns.UpdateRoleLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            records = response.data.records;
            console.log({ records });

            if (flag == 1) {
              thisIns.sweetAlert("success", output, false);
              //   thisIns.Members = records;
              thisIns.searchForm();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SubmitFlag = false;
          });
      } else {
        var message = "Username should not be empty.";
        this.sweetAlert("error", message, false);
      }
    },
    addItem() {
      console.log("addItem called");
      this.dialog = true;
    },
    initialize() {
      this.rows = [];
    },

    editItem(tr) {
      this.editedIndex = this.rows.indexOf(tr);
      this.editedItem = Object.assign({}, tr);
      this.dialog = true;
    },

    deleteItem(tr) {
      this.editedIndex = this.rows.indexOf(tr);
      this.editedItem = Object.assign({}, tr);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.rows.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.rows[this.editedIndex], this.editedItem);
      } else {
        this.rows.push(this.editedItem);
      }
      this.close();
    },
  },
  beforeMount() {
    this.DeleteFlag = true;
    /*
        this.tableColumns1.push(
          { label: "Status", field: "ActiveStatusTxt" },
          { label: "Register No", field: "AwardsRegisterNo" },
          { label: "Zone Name", field: "ZoneName" },
          { label: "LOM", field: "LomName" },
          { label: "Award Type", field: "AwardsTypeTitle" },
          { label: "Token", field: "AwardsToken" },
          { label: "President Details", field: "LomPresidentDetails" },
          { label: "100% Efficiency", field: "FullEfficiencyPoints" },
          { label: "Judges Score", field: "TotalJudgeScoreTxt" },
          { label: "Score", field: "TotalAssessmentScore" }
        );
        */
    this.tableColumns1.push(
      { text: "#", value: "sno" },
      { text: "Member Id", value: "MembershipId" },
      { text: "Member Name", value: "MemberName" },
      { text: "Mobile No.", value: "MobileNo" },
      { text: "Email", value: "EmailId" },
      { text: "Address", value: "AddressTxt" },
      { text: "Area", value: "Area" },
      { text: "City", value: "CityName" },
      { text: "State", value: "StateName" },
      { text: "actions", value: "actions" }
    );

    this.tableColumns2.push(
      { text: "#", value: "sno" },
      { text: "Member Id", value: "MembershipId" },
      { text: "Member Name", value: "MemberName" },
      { text: "Mobile No.", value: "MobileNo" },
      { text: "Email", value: "EmailId" },
      { text: "Address", value: "AddressTxt" }
    );
    this.refreshPageData();
  },
};
</script>

<style lang="scss">
</style>
